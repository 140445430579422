<template>
  <div class="relative">

    <img :src="imageUrl" alt="Baby image"/>

    <div class="absolute play-btn-container">
      <button @click="showModal=true"
              class="text-white hover:text-[#e0bbff] text-red-200 rounded-full flex" style="">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" class="play-btn">
          <defs>
            <mask id="ipTPlay0">
              <g fill="#555" stroke="#fff" stroke-linejoin="round" stroke-width="4">
                <path d="M24 44c11.046 0 20-8.954 20-20S35.046 4 24 4S4 12.954 4 24s8.954 20 20 20Z"/>
                <path d="M20 24v-6.928l6 3.464L32 24l-6 3.464l-6 3.464z"/>
              </g>
            </mask>
          </defs>
          <path fill="currentColor" d="M0 0h48v48H0z" mask="url(#ipTPlay0)"/>
        </svg>
        <!--        <span class="pl-1">{{ localizedPlay }}</span>-->
      </button>
    </div>


    <!-- Main modal -->

    <div
        v-if="showModal"
        @click="showModal=false"
        id="default-modal" tabindex="-1" aria-hidden="true"
        class="bg-[#000000b5] overflow-y-auto overflow-x-hidden fixed top-0 right-0 bottom-0 left-0 z-50 flex justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full">
      <div class="relative p-4 w-full max-w-2xl max-h-full">
        <!-- Modal content -->
        <div class="relative bg-white rounded-lg shadow dark:bg-gray-700 p-2" @click="preventDefault">


          <video ref="videoRef" loop controls autoplay class="w-full">
            <source :src="'/nani_promo_compressed_' + locale + '.mp4'" type="video/mp4"/>
            Your browser does not support the video tag.
          </video>
          <button class="absolute top-0 right-0 w-6 h-6 bg-white text-black dark:bg-black dark:text-white rounded-full"
                  @click="showModal=false">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                 stroke="currentColor" class="w-6 h-6">
              <path stroke-linecap="round" stroke-linejoin="round" d="M6 18 18 6M6 6l12 12"/>
            </svg>
          </button>
        </div>

      </div>
    </div>

    <!--    <div-->
    <!--        class="fixed left-0 top-0 w-full h-screen pt-10 mx-auto inset-x-0 bg-gray-700 bg-opacity-50 overflow-y-auto overflow-x-hidden outline-none"-->
    <!--        style="z-index: 2000;"-->
    <!--        id="exampleModal"-->
    <!--    >-->
    <!--      <div class="flex justify-center">-->
    <!--        <div class="flex border-2 border-black rounded-xl overflow-hidden max-w-[100%] w-[250px]">-->


    <!--        </div>-->
    <!--      </div>-->

    <!--    </div>-->
  </div>
</template>

<style scoped>
.play-btn-container {
  top: 50%;
  left: 34%;
}

.play-btn {
  width: 20%;
}

@media only screen and (max-width: 600px) {
  .play-btn-container {
    left: 33%;
  }
}
</style>

<script setup>
import {ref} from 'vue'

const props = defineProps({
  locale: String, localizedPlay: String, imageUrl: String
});

const preventDefault = (e) => {
  e.preventDefault();
  e.stopPropagation();
}

const showModal = ref(false)

</script>
